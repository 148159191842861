import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "styled-components";
import { phoneMask } from "../../utils/field-mask";
import Input from "../Input";
import Select from "../Select";
import * as S from "./styles";

const Contact = () => {
  const { title } = useContext(ThemeContext);
  const [inputName, setInputName] = useState("");

  const handleInputName = event => {
    setInputName(event.target.value);
  };

  const handlePhone = event => {
    let input = event.target;
    input.value = phoneMask(input.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() =>
        toast.success("Obrigado, sua mensagem foi enviada!", { theme: title })
      )
      .catch(error => toast.error(error, { theme: title }))
      .finally(() => form.reset());
  };

  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "roman.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);

  return (
    <S.Contact name="contact">
      <S.BGImageStyled brightness="0.3" image={image} alt="templo romano">
        <S.TitleContent>
          <h2>Entre em contato conosco</h2>
          <h3>Obtenha Avaliação Gratuita do Caso</h3>
          <hr />
        </S.TitleContent>

        <S.Form
          name="communicate"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          {/* Netlify configurations */}
          <input type="hidden" name="form-name" value="communicate" />
          <input
            type="hidden"
            name="subject"
            data-remove-prefix
            value={`GM Advogados - ${inputName}`}
          />

          <Input
            name="nome"
            onChange={handleInputName}
            placeholder="Digite seu nome"
            required
            autoComplete="off"
          />
          <Input
            name="email"
            type="email"
            placeholder="Digite seu e-mail"
            required
            autoComplete="off"
          />
          <Input
            name="telefone"
            type="tel"
            placeholder="Digite seu telefone"
            maxLength="15"
            onKeyUp={handlePhone}
            autoComplete="off"
          />

          <Select
            defaultValue=""
            name="assunto"
            required
            options={[
              {
                value: "Aposentadoria e Auxílios",
                label: "Aposentadoria e Auxílios",
              },
              {
                value: "Direito Trabalhista",
                label: "Direito Trabalhista" },
              {
                value: "Direito do Consumidor",
                label: "Direito do Consumidor",
              },
              {
                value: "Outro",
                label: "Outro"
              },
            ]}
          />

          <textarea
            name="mensagem"
            className="message"
            placeholder="Digite sua mensagem"
            required
            autoComplete="off"
          />

          <button className="submit" type="submit">
            Enviar mensagem
          </button>
        </S.Form>
      </S.BGImageStyled>

      <ToastContainer />
    </S.Contact>
  );
};

export default Contact;
