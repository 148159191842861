import styled from "styled-components";
import {
  BGImageBase,
  ContainerBaseStyle,
  H1Base,
} from "../../styles/general-styles";

export const Home = styled.section`
  width: 100%;
`;

export const BGImageStyled = styled(BGImageBase)`
  height: 60vh;

  @media screen and (max-width: 575px) {
    ::after {
      background-size: cover !important;
    }
  }

  @media screen and (min-width: 768px) {
    height: 70vh;
  }
`;

export const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.75rem;
  padding: 0 15px;

  ${ContainerBaseStyle};

  h1 {
    ${H1Base};
    color: ${({ theme }) => theme.colors.white};
    line-height: 2.2rem;
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.fontSize.xl3};

    @media screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.xl5};
      line-height: 3.25rem;
    }
    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.fontSize.xl6};
      line-height: 3.75rem;
    }
    @media screen and (min-width: 1200px) {
      font-size: ${({ theme }) => theme.fontSize.xl7};
      line-height: 5.5rem;
    }
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    line-height: 1.4rem;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.fontSize.lg};
      line-height: 1.75rem;
    }
    @media screen and (min-width: 1200px) {
      width: 60%;
    }
  }
`;

export const Testimonial = styled.div`
  width: 100%;
  display: flex;
  column-gap: 0.5rem;
  padding: 0 15px;

  @media screen and (min-width: 768px) {
    column-gap: 1rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (min-width: 768px) {
      row-gap: 0.5rem;
    }

    img {
      border: 3px solid ${({ theme }) => theme.colors.white};
      border-radius: 50%;
    }

    h3 {
      font-family: ${({ theme }) => theme.font.signature};
      font-size: ${({ theme }) => theme.fontSize.xl2};
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 0;

      @media screen and (min-width: 768px) {
        font-size: ${({ theme }) => theme.fontSize.xl3};
      }
    }

    span {
      font-size: ${({ theme }) => theme.fontSize.xs};
      font-weight: 400;
      color: ${({ theme }) => theme.colors.white};
      text-transform: uppercase;
    }
  }
`;
