import PropTypes from "prop-types";
import React from "react";
import * as S from "./styles";

const Select = ({ options, ...rest }) => (
  <S.Select defaultValue="" {...rest}>
    <option value="" disabled hidden>
      Selecione seu assunto
    </option>
    {options.map(option => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    })}
  </S.Select>
);

Select.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  rest: PropTypes.any,
};

export default Select;
