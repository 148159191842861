import * as React from "react";
import Icon from "../Icons/icon";
import { areasCardData } from "../../utils/data";
import * as S from "./styles";

const Areas = () => (
  <S.Areas name="areas">
    <S.TitleContent>
      <h2>Somos especialistas em</h2>
      <h3>Áreas de Prática Jurídica</h3>
      <hr />
    </S.TitleContent>

    <S.Cards>
      {areasCardData.map((card, index) => (
        <S.Card key={index} to={card.page}>
          {card.icon}
          <h4>{card.title}</h4>
          <p>{card.description}</p>

          <span className="link-text">
            Ver mais
            <Icon name="ArrowRight" />
          </span>
        </S.Card>
      ))}
    </S.Cards>
  </S.Areas>
);

export default Areas;
