import PropTypes from "prop-types";
import React from "react";
import * as S from "./styles";

const Input = ({ name, ...rest }) => {
  return <S.Input className={name} name={name} type="text" {...rest} />;
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  rest: PropTypes.any,
};

export default Input;
