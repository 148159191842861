import { Link } from "gatsby";
import styled from "styled-components";
import { TitleContentBase, H1Base } from "../../styles/general-styles";

export const Areas = styled.section`
  width: 100%;
  padding: 5rem 0;
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color 0.45s ease-out;

  @media screen and (min-width: 576px) {
    padding: 7rem 0;
  }
`;

export const TitleContent = styled(TitleContentBase)`
  h2 {
    color: ${({ theme }) => theme.colors.text};
  }

  h3 {
    color: ${({ theme }) => theme.colors.text};
    letter-spacing: 1px;
    margin-bottom: 0;

    @media screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.xl2};
    }

    ${H1Base};
  }

  hr {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 2.5rem;
  max-width: 100%;
  margin: 1.25rem;

  @media screen and (min-width: 576px) {
    max-width: 400px;
    margin: 0 auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }
  @media screen and (min-width: 1024px) {
    max-width: 1000px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
  @media screen and (min-width: 1400px) {
    max-width: 1200px;
  }
`;

export const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.5rem;
  overflow: hidden;
  border-radius: 0.5em;
  text-decoration: none;
  padding: 1.5em 1.75em;
  box-shadow: 0 0.5em 2em 0em ${({ theme }) => theme.colors.cardShadow};
  transition: transform 0.45s ease, background 0.45s ease;

  @media screen and (min-width: 576px) {
    padding: 2em 2.25em;
  }
  @media screen and (min-width: 1024px) {
    row-gap: unset;
  }

  .area-icon {
    width: 80px;
    height: 80px;

    @media screen and (min-width: 576px) {
      width: 120px;
      height: 120px;
    }

    path {
      fill: ${({ theme }) => theme.colors.text};
      transition: fill 0.45s ease;
    }
  }

  h4 {
    font-family: ${({ theme }) => theme.font.basic};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.2;
    margin: 1rem 0 0.5rem;
    padding-top: 0.5rem;
    border-top: 2px solid ${({ theme }) => theme.colors.secondaryLight};
    transition: all 200ms linear;

    @media screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.base};
      margin: 1.75rem 0 0.75rem;
    }
  }

  p {
    opacity: 1;
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.textGray};
    font-weight: 400;
    line-height: 1.4;
    margin: 0 0 1rem;
    transition: all 200ms linear;

    @media screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.md};
    }

    @media screen and (min-width: 1024px) {
      opacity: 0;
      color: ${({ theme }) => theme.colors.white};
      transform: translateY(-1em);
      transition: opacity 0.45s ease, transform 0.5s ease;
    }
  }

  .link-text {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 500;
    transition: color 0.45s ease;

    @media screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.base};
    }

    svg {
      margin-left: 0.5em;
      transition: transform 0.6s ease;
      width: 20px;
      height: 14px;

      @media screen and (min-width: 576px) {
        width: 22px;
        height: 16px;
      }

      path {
        fill: ${({ theme }) => theme.colors.secondary};
        transition: fill 0.45s ease;
      }
    }
  }

  :hover {
    @media screen and (min-width: 1024px) {
      background: ${({ theme }) => theme.colors.secondary};
      transform: scale(1.02);

      svg {
        path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }

      h4 {
        color: ${({ theme }) => theme.colors.white};
      }

      p {
        opacity: 1;
        transform: none;
      }

      .link-text {
        color: ${({ theme }) => theme.colors.white};

        svg {
          animation: chevron-up 1s infinite alternate;

          @keyframes chevron-up {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(0.3em);
            }
          }

          path {
            fill: ${({ theme }) => theme.colors.white};
          }
        }
      }
    }
  }
`;
