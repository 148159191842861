import styled from "styled-components";

export const Input = styled.input`
  display: block;
  width: 100%;
  height: 50px;
  padding: 0 0.75rem;
  font-family: ${({ theme }) => theme.font.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
  border-radius: 0;
  border: 0;
  outline: none;

  ::placeholder {
    font-size: ${({ theme }) => theme.fontSize.sm};
    color: ${({ theme }) => theme.colors.gray};
  }
`;
