import styled from "styled-components";

export const Select = styled.select`
  display: block;
  width: 100%;
  height: 50px;
  padding-left: 0.75rem;
  padding-right: 2.5rem;
  font-family: ${({ theme }) => theme.font.main};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.black};
  border-radius: 0;
  border: 0;
  outline: none;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='square' stroke-linejoin='square'><polyline points='6 9 12 15 18 9'></polyline></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;

  :invalid,
  option[value=""] {
    color: ${({ theme }) => theme.colors.gray};
  }

  option {
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 300;
    color: ${({ theme }) => theme.colors.black};
  }
`;
