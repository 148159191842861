import styled from "styled-components";
import {
  BGImageBase,
  TitleContentBase,
  H1Base,
} from "../../styles/general-styles";

export const Contact = styled.section`
  width: 100%;

  .Toastify__toast-body {
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${({ theme }) => theme.fontSize.sm};

    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.fontSize.md};
    }

    .Toastify__toast-icon {
      svg {
        fill: ${({ theme }) => theme.colors.success};
      }
    }
  }

  .Toastify__progress-bar--success {
    background: ${({ theme }) => theme.colors.success};
  }
`;

export const BGImageStyled = styled(BGImageBase)`
  flex-direction: column;
  padding: 5rem 0;
  background-attachment: fixed;
`;

export const TitleContent = styled(TitleContentBase)`
  h2 {
    color: ${({ theme }) => theme.colors.white};
  }

  h3 {
    color: ${({ theme }) => theme.colors.white};
    letter-spacing: 1px;
    margin-bottom: 0;

    @media screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.xl2};
    }
    ${H1Base};
  }

  hr {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Form = styled.form`
  width: calc(100% - 2.5rem);
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  grid-row-gap: 1.75rem;

  @media screen and (min-width: 576px) {
    max-width: calc(100% - 100px);
    grid-template-columns: repeat(2, 1fr);
    gap: 1.75rem;

    .name {
      grid-area: name;
    }
    .email {
      grid-area: email;
    }
    .tel {
      grid-area: tel;
    }
    .subject {
      grid-area: subject;
    }
    .message {
      grid-area: message;
    }
    .submit {
      grid-area: submit;
    }

    grid-template-areas:
      "name email"
      "tel subject"
      "message message"
      "submit submit";
  }

  @media screen and (min-width: 768px) {
    max-width: 660px;
  }

  @media screen and (min-width: 1024px) {
    max-width: 750px;
  }

  textarea {
    display: block;
    width: 100%;
    height: 175px;
    padding: 0.75rem;
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 300;
    border-radius: 0;
    border: 0;
    outline: none;
    resize: none;

    ::placeholder {
      font-size: ${({ theme }) => theme.fontSize.sm};
      color: ${({ theme }) => theme.colors.gray};
    }
  }

  button {
    display: block;
    width: 100%;
    padding: 1rem 0;
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.success};
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    border: none;
    outline: none;
    transition: all 0.5s ease;

    :hover {
      background-color: ${({ theme }) => theme.colors.successDark};
    }
  }
`;
