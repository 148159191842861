import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import * as S from "./styles";

const Home = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "hammer.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);

  return (
    <S.Home name="home">
      <S.BGImageStyled
        brightness="0.6"
        size="130%"
        position="0 60%"
        image={image}
        alt="imagem de um martelo usado por um juiz com background preto"
      >
        <S.SectionTitle>
          <h1>
            Advocacia Especializada <br /> em INSS
          </h1>
          <p>
            Oferecemos consultoria jurídica com expertise em aposentadoria e auxílios. Comprometidos com a excelência e resultados para nossos clientes.
          </p>
        </S.SectionTitle>
      </S.BGImageStyled>
    </S.Home>
  );
};

export default Home;
