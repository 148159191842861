import * as React from "react";
import Areas from "../components/Areas";
import Contact from "../components/Contact";
import Home from "../components/Home";
import WhatsAppButton from "../components/WhatsAppBtn";
import Seo from "../components/seo";

const IndexPage = () => {
  return (
    <React.Fragment>
      <WhatsAppButton />

      <Home />
      <Areas />
      <Contact />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Direito Previdenciário, Trabalhista e do Consumidor"
    description="Advocacia especializada em direito trabalhista, do consumidor e previdenciário. Com expertise em aposentadoria e auxílios, estamos comprometidos com os resultados para nossos clientes."
  />
);

export default IndexPage;
